<template>
	<v-list v-if="canShowAutomations" style="width: 100%">
		<v-list-group v-model="isOpen" lazy style="position: relative">
			<template v-slot:activator>
				<v-list-tile>
					<v-list-tile-content v-t="'ecm.automations'" />
				</v-list-tile>
			</template>
			<v-alert v-if="folderApplicationsList.length === 0 && !applicationsLoading" class="d-flex ma-0" color="warning" icon="warning" :value="true">
				{{ $t('ecm.no_automation_available') }}
				<w-btn color="info" small @click="goToApplicationPage()"> {{ $t('actions.click_here') }} </w-btn>
			</v-alert>
			<w-flex v-if="folderApplicationsList.length > 0 && !applicationsLoading" pl-2>
				<w-text-info :text="$t('ecm.automation_sending_info')"></w-text-info>
			</w-flex>
			<v-list-tile v-for="application in folderApplicationsList" :key="application.id">
				<v-list-tile-content>
					{{ application.raw_title ?? application.title }}
				</v-list-tile-content>
				<v-list-tile-action>
					<w-switch :value="application.is_active" @input="onSwitchStateChanged(application)" />
				</v-list-tile-action>
			</v-list-tile>
		</v-list-group>
	</v-list>
</template>

<script>
import { mapState } from 'vuex'
import DocumentsAutomationsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsAutomationsModuleGuard'

export default {
	name: 'FolderAutomationSelector',
	mixins: [DocumentsAutomationsModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			folderApplicationsList: [],
			automationsIds: [],
			cancelToken: null,
			isListingDelayed: false,
			isOpen: false,
			applicationsLoading: false
		}
	},
	computed: {
		...mapState({
			applications: state => state.documents.automations.applications,
			isAccountant: state => state.auth.isAccountant
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		canShowAutomations: function () {
			return this.model && this.model.is_folder && !this.model.is_deleted && !this.model.is_trash && this.isAccountant
		}
	},
	watch: {
		applications: {
			immediate: true,
			handler: function () {
				this.computeApplicationsList()
			}
		},
		automationsIds: {
			immediate: true,
			handler: function () {
				this.computeApplicationsList()
			}
		},
		isOpen: {
			immediate: false,
			handler: function () {
				this.onOpenStateChanged()
			}
		},
		'value.id': {
			immediate: true,
			handler: function () {
				this.onFolderChanged()
			}
		},
		vendorId: {
			handler: function () {
				this.loadVendorApplications()
			},
			immediate: true
		}
	},
	mounted () {},
	destroyed () {
		this.service.clearApplications()
	},
	methods: {
		loadVendorApplications: function () {
			if (this.canShowAutomations) {
				const params = {
					filters: {
						is_automation: true
					}
				}
				this.service.loadApplications(this.accountingFirmId, this.vendorId, params)
			}
		},
		computeApplicationsList: function () {
			this.folderApplicationsList = []
			this.applications.forEach(application => {
				this.folderApplicationsList.push({
					...application,
					is_active: this.automationsIds.includes(application.id)
				})
			})
		},
		goToApplicationPage: function () {
			this.appService.goTo('applications')
		},
		onFolderChanged: function () {
			if (this.model?.is_folder) {
				let promise
				if (this.isOpen && this.applications.length > 0) {
					this.isListingDelayed = false
					this.applicationsLoading = true
					promise = this.service.getFolderApplications(this.vendorId, this.model.id)
				} else {
					this.isListingDelayed = true
					promise = Promise.resolve([])
				}
				promise.then(folderAutomations => {
					this.automationsIds.clear()
					folderAutomations.forEach(({ id }) => {
						this.automationsIds.push(id)
					})
					this.computeApplicationsList()
					this.applicationsLoading = false
				})
			}
		},
		onOpenStateChanged: function () {
			if (this.isOpen && this.isListingDelayed) {
				this.onFolderChanged(this.model?.id)
			}
		},
		onSwitchStateChanged: function (application) {
			let promise
			if (!application.is_active) {
				promise = this.service.createFolderApplications(this.vendorId, this.model?.id, application.id)
			} else {
				promise = this.service.deleteFolderApplications(this.vendorId, this.model?.id, application.id)
			}
			promise.then(() => {
				application.is_active = !application.is_active
			})
		}
	}
}
</script>
