import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

export default {
	applications: {
		create: NotImplementedYet,
		read: function (accountingFirmId, vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
			RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
			return Backend.GET(
				URLS.api.accountingFirms.vendors.applications.uri,
				[accountingFirmId, vendorId],
				params,
				doCancelPreviousRequest,
				doCancelAllOtherRequests
			)
		},
		update: NotImplementedYet,
		delete: NotImplementedYet
	},
	folder: {
		applications: {
			create: function (vendorId, folderId, applicationId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments(vendorId, folderId, applicationId, doCancelPreviousRequest, doCancelAllOtherRequests)
				return Backend.POST(
					URLS.api.vendors.folders.applications.uri,
					[vendorId, folderId, applicationId],
					params,
					doCancelPreviousRequest,
					doCancelAllOtherRequests
				)
			},
			read: function (vendorId, folderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, folderId: folderId })
				return Backend.GET(URLS.api.vendors.folders.applications.uri, [vendorId, folderId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: function (vendorId, folderId, applicationId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments(vendorId, folderId, applicationId, doCancelPreviousRequest, doCancelAllOtherRequests)
				return Backend.DELETE(
					URLS.api.vendors.folders.applications.uri,
					[vendorId, folderId, applicationId],
					params,
					doCancelPreviousRequest,
					doCancelAllOtherRequests
				)
			}
		}
	}
}
