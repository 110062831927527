// ============================================================================
// DocumentsManagerService
// -----------------------
// DocumentsManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/DocumentsAutomationsApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		applications: {
			create: NotImplementedYet,
			read: function (accountingFirmId, vendorId, params = null) {
				RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
				return API.applications.read(accountingFirmId, vendorId, params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		folder: {
			applications: {
				create: function (vendorId, folderId, applicationId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId, applicationId: applicationId })
					return API.folder.applications.create(vendorId, folderId, applicationId, params)
				},
				read: function (vendorId, folderId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId })
					return API.folder.applications.read(vendorId, folderId, params)
				},
				update: NotImplementedYet,
				delete: function (vendorId, folderId, applicationId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId, applicationId: applicationId })
					return API.folder.applications.delete(vendorId, folderId, applicationId, params)
				}
			}
		}
	},
	services: {
		applications: {
			create: NotImplementedYet,
			read: function (accountingFirmId, vendorId, params = null) {
				RequiredArguments({ accountingFirmId: accountingFirmId, vendorId: vendorId })
				return Private.requests.applications.read(accountingFirmId, vendorId, params)
			},
			load: function (accountingFirmId, vendorId, params = null) {
				return Private.services.applications.read(accountingFirmId, vendorId, params).then(applications => Private.store.setApplications(applications))
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		folder: {
			applications: {
				create: function (vendorId, folderId, applicationId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId, applicationId: applicationId })
					return Private.requests.folder.applications.create(vendorId, folderId, applicationId, params)
				},
				read: function (vendorId, folderId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId })
					return Private.requests.folder.applications.read(vendorId, folderId, params)
				},
				update: NotImplementedYet,
				delete: function (vendorId, folderId, applicationId, params = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId, applicationId: applicationId })
					return Private.requests.folder.applications.delete(vendorId, folderId, applicationId, params)
				}
			}
		}
	},
	store: {
		setApplications: function (applications) {
			RequiredArguments({ applications: applications })
			return store.dispatch('documents/automations/setApplications', applications)
		},
		clearApplications: function () {
			return store.dispatch('documents/automations/clearApplications')
		}
	}
}

// -------
// Exports
// -------
export default {
	createApplications: Private.services.applications.create,
	getApplications: Private.services.applications.read,
	loadApplications: Private.services.applications.load,
	updateApplications: Private.services.applications.update,
	deleteApplications: Private.services.applications.delete,

	createFolderApplications: Private.services.folder.applications.create,
	getFolderApplications: Private.services.folder.applications.read,
	loadFolderApplications: Private.services.folder.applications.load,
	updateFolderApplications: Private.services.folder.applications.update,
	deleteFolderApplications: Private.services.folder.applications.delete,

	clearApplications: Private.store.clearApplications
}
